<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="6" md="12">
        <analytics-congratulation :data="congratulations" />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="ordersTotal"
          icon="UsersIcon"
          :statistic="kFormatter(ordersTotal.total)"
          statistic-title="Total number of all orders"
          :chart-data="ordersTotal.series"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(ordersRecevied.total)"
          statistic-title="Total completed sales"
          :chart-data="ordersRecevied.series"
        />
      </b-col> </b-row
    ><!-- Stats Card Vertical -->

    <b-row class="match-height">
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical icon="EyeIcon" :statistic="stats.completed_orders" statistic-title="Completed Orders" color="info" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical hide-chart color="danger" icon="TruckIcon" :statistic="stats.cancelled_orders" statistic-title="Cancelled Orders" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="success" icon="AwardIcon" :statistic="stats.pending_orders" statistic-title="Pending Orders" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="warning" icon="MessageSquareIcon" :statistic="stats.customers" statistic-title="Customers" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="danger" icon="ShoppingBagIcon" :statistic="stats.coupons" statistic-title="Coupons" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="primary" icon="HeartIcon" :statistic="stats.products" statistic-title="Products" />
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <h6 class="section-label mx-1 mb-2">Orders</h6>
        <b-col cols="12" class="mb-2">
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
              </div>
            </b-form-group>
          </div>
          <vue-good-table
            :columns="columns"
            :rows="orders"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status + 1)">
                  {{ status[0][props.row.status + 1] }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'sub_total'">
                €{{ parseFloat(props.row.sub_total + props.row.shipping - props.row.discount + props.row.service_charge).toFixed(2) }}
              </span>
              <span v-else-if="props.column.field === 'name'">
                <router-link :to="'/takeaway/shop/customers/' + props.row.user_id">{{ props.row.name }} #{{ props.row.user_id }}</router-link>
              </span>
              <span v-else-if="props.column.field === 'id'">
                <router-link :to="'/takeaway/shop/orders/' + props.row.id">{{ props.row.id }}</router-link>
              </span>
              <span v-else-if="props.column.field === 'token'">
                <span v-if="props.row.token != 'cash' && props.row.token != 'sage'">Stripe</span>
                <span v-else-if="props.row.token != 'cash' && props.row.token == 'sage'">Sage</span>
                <span v-else>Cash</span>
                <template v-if="props.row.origin"> ({{ props.row.origin }})</template>
              </span>
              <span v-else-if="props.column.field === 'updated_at'">
                {{ props.row.updated_at | moment("DD/MM/YYYY HH:mm") }}
              </span>

              <span v-else-if="props.column.field === 'action'">
                <b-button type="submit" variant="primary" block :to="'/takeaway/shop/orders/' + props.row.id">
                  <feather-icon icon="BookOpenIcon" class="mr-50" />
                  <span>Preview</span>
                </b-button>
              </span>

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['500', '1000', '2500']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { BRow, BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton } from "bootstrap-vue";

import StatisticCardWithAreaChart from "./components/dashboard/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import AnalyticsCongratulation from "./components/dashboard/AnalyticsCongratulation.vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";

import AnalyticsAvgSessions from "./components/dashboard/AnalyticsAvgSessions.vue";
import AnalyticsSupportTracker from "./components/dashboard/AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./components/dashboard/AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "./components/dashboard/AnalyticsSalesRadarChart.vue";
import AnalyticsAppDesign from "./components/dashboard/AnalyticsAppDesign.vue";

import useJwt from "@/auth/jwt/useJwt";
import _ from "lodash";
import moment from "moment";

import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCol,
    BRow,
    BButton,
    BCol,
    StatisticCardWithLineChart,
    StatisticCardHorizontal,
    StatisticCardVertical,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
  },
  data() {
    return {
      stats: {
        completed_orders: 0,
        cancelled_orders: 0,
        pending_orders: 0,
        customers: 0,
        coupons: 0,
        products: 0,
      },
      ordersTotal: {
        total: "",
        series: [],
      },
      ordersRecevied: {
        total: "",
        series: [],
      },
      data: {},
      congratulations: {
        name: "",
        salesToday: "0",
        salesWorth: "0",
      },
      user: false,
      sort_orders: [],
      orders: [],
      customers: [],
      products: [],

      pageLength: 500,
      columns: [
        {
          label: "ID",
          field: "id",
          width: "80px",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Customer",
          field: "name",
        },
        {
          label: "User ID",
          hidden: true,
          field: "user_id",
        },
        {
          label: "Payment",
          field: "token",
        },
        {
          label: "Finished",
          field: "updated_at",
          width: "200px",
        },
        {
          label: "Total",
          field: "sub_total",
          width: "120px",
        },
        {
          label: "Action",
          field: "action",
          width: "150px",
        },
      ],
      searchTerm: "",
      status: [
        {
          0: "Refunded",
          1: "Queued",
          2: "Accepted",
          3: "Out for delivery",
          4: "Completed",
          5: "Failed",
        },
        {
          0: "danger",
          1: "primary",
          2: "info",
          3: "info",
          4: "success",
          5: "danger",
        },
      ],
      coupons: null,
    };
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "danger",
        1: "primary",
        2: "info",
        3: "info",
        4: "success",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem("userData"));

    if (this.user == null || this.user.first_name == null) this.$router.go("/logout");
    await this.getData();

    this.congratulations.name = this.user.first_name + " " + this.user.last_name;

    this.congratulations.salesToday = 0;
    this.congratulations.salesWorth = 0;

    this.stats.completed_orders = 0;
    this.stats.cancelled_orders = 0;
    if (this.orders) {
      for (let y = 0; y < this.orders.length; y++) {
        // console.log(this.orders[y])
        if (this.orders[y].status == "3") {
          this.stats.completed_orders++;
        } else if (this.orders[y].status == "-1") {
          this.stats.cancelled_orders++;
        } else {
          this.stats.pending_orders++;
        }
      }
    }

    let grouped_items = _.groupBy(this.orders, (b) => moment(b.updated_at).startOf("month").format("YYYY/MM"));
    _.values(grouped_items).forEach((arr) => arr.sort((a, b) => moment(a.updated_at).day() - moment(b.updated_at).day()));

    this.sort_orders = grouped_items;
    let currentMonth = new Date().getMonth() + 1;
    if (currentMonth <= 9) currentMonth = "0" + currentMonth;
    let current = new Date().getFullYear() + "/" + currentMonth;

    if (grouped_items[current]) {
      for (let x = 0; x < grouped_items[current].length; x++) {
        if (grouped_items[current][x].status == 3) {
          this.congratulations.salesToday++;
          this.congratulations.salesWorth += parseFloat(grouped_items[current][x].sub_total);
          if (grouped_items[current][x].shipping) this.congratulations.salesWorth += parseFloat(grouped_items[current][x].shipping);
          if (grouped_items[current][x].discount) this.congratulations.salesWorth -= parseFloat(grouped_items[current][x].discount);
        }
      }

      this.congratulations.salesToday = this.congratulations.salesToday.toString();
      this.congratulations.salesWorth = "€" + this.congratulations.salesWorth.toFixed(2).toString();
    } else {
      this.congratulations.salesToday = "0";
    }

    this.ordersTotal.series = [{ name: "Orders", data: [] }];
    this.ordersRecevied.series = [{ name: "Orders Total", data: [] }];
    let totalAmount = 0;
    let totalEuro = 0;
    let ordersFinished = 0;
    let allOrdersFinished = 0;
    // console.log(grouped_items[Object.keys(grouped_items)[Object.keys(grouped_items).length - 1]])

    if (Object.keys(grouped_items)) {
      for (let x = 0; x < Object.keys(grouped_items).length; x++) {
        ordersFinished = 0;
        totalEuro = 0;

        for (let y = 0; y < grouped_items[Object.keys(grouped_items)[x]].length; y++) {
          ordersFinished++;
          allOrdersFinished++;
          if (grouped_items[Object.keys(grouped_items)[x]][y].status == "3") {
            totalEuro += parseFloat(grouped_items[Object.keys(grouped_items)[x]][y].sub_total);
            if (grouped_items[Object.keys(grouped_items)[x]][y].shipping) totalEuro += parseFloat(grouped_items[Object.keys(grouped_items)[x]][y].shipping);
            if (grouped_items[Object.keys(grouped_items)[x]][y].discount) totalEuro -= parseFloat(grouped_items[Object.keys(grouped_items)[x]][y].discount);
          }
        }
        totalAmount += parseFloat(totalEuro.toFixed(2));
        this.ordersRecevied.series[0].data.push(totalEuro.toFixed(2));
        this.ordersTotal.series[0].data.push(ordersFinished);
      }
    }
    this.ordersTotal.series[0].data = this.ordersTotal.series[0].data.reverse();
    this.ordersTotal.total = allOrdersFinished;
    this.ordersRecevied.series[0].data = this.ordersRecevied.series[0].data.reverse();

    this.ordersRecevied.total = "€" + totalAmount.toFixed(2);
    let dataCharts = this.ordersRecevied.series[0].data;
    this.ordersRecevied.series[0].data = [];
    let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

    for (let x = 0; x < dataCharts.length; x++) {
      this.ordersRecevied.series[0].data[x] = dataCharts[x];
    }
    console.log(this.ordersRecevied.series[0].data);
    this.$forceUpdate();
  },
  methods: {
    async getData() {
      // this.orders = JSON.parse(localStorage.getItem("userOrders"));
      // this.coupons = JSON.parse(localStorage.getItem("userCoupons"));
      // this.customers = JSON.parse(localStorage.getItem("userCustomers"));
      // this.products = JSON.parse(localStorage.getItem("userProducts"));
      // if (this.orders == null || this.orders.length == 0) {
      await useJwt
        .get_all_orders()
        .then((response) => {
          let order = [];
          this.orders = [];
          for (let x = 0; x < response.data.data.length; x++) {
            if (response.data.data[x].deleted == 0) {
              order.push(response.data.data[x]);
            }
          }
          this.orders = order;
          localStorage.setItem("userOrders", JSON.stringify(order));
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your order data from the server.`,
            },
          });
        });
      // }
      // if (this.coupons == null) {
      await useJwt
        .get_coupons()
        .then((response) => {
          let data = response.data.data;
          if (data) {
            for (let x = 0; x < data.length; x++) {
              data[x].status = false;
            }

            localStorage.setItem("userCoupons", JSON.stringify(data));

            this.coupons = data;
            if (this.coupons.length) {
              this.stats.coupons = this.coupons.length;
            } else {
              this.stats.coupons = 0;
            }
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your coupon data from the server.`,
            },
          });
        });
      // }
      // if (this.customers == null) {
      await useJwt
        .get_all_customers()
        .then((response) => {
          localStorage.setItem("userCustomers", JSON.stringify(response.data.data));
          this.customers = response.data.data.reverse();
          if (this.customers.length) {
            this.stats.customers = this.customers.length;
          } else {
            this.stats.customers = 0;
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your customer data from the server.`,
            },
          });
        });
      // }
      // if (this.products == null) {
      await useJwt
        .list_products()
        .then((response) => {
          localStorage.setItem("userProducts", JSON.stringify(response.data.data));
          this.products = response.data.data.reverse();

          if (this.products.length) {
            this.stats.products = this.products.length;
          } else {
            this.stats.products = 0;
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your customer data from the server.`,
            },
          });
        });
      // }
    },
    kFormatter,
    getCouponName(coupon_id) {
      if (coupon_id === null) return " - ";
      return _.find(this.coupons, { id: coupon_id }).title;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
